import React from 'react'
import AddBlog from '../addBlog'

const AdminDashboard = () => {
  return (
    <div>
      <AddBlog />
    </div>
  )
}

export default AdminDashboard
